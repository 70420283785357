import { EditorSDK, EventType, PageData, PageRef } from '@wix/platform-editor-sdk';
import { TpaPageId } from '@wix/pricing-plans-router-utils';
import { retry } from '@wix/pricing-plans-utils/http';
import type { EditorScriptFlowAPI } from '@wix/yoshi-flow-editor';
import pricingPlans from '../../.application.json';
import { captureEditorException } from './editor';
import { installRouter } from './installRouter';
import { MPA_ON_ECOM_PAGES, MPA_PAGES, PageInstallationInfo } from './isMultiPageApp';

const appDefinitionId = pricingPlans.appDefinitionId;
const TOKEN = '';

interface SplitPagesOptions {
  firstInstall: boolean;
  withEcom: boolean;
}

export async function installSplitPages(
  editorSDK: EditorSDK,
  flowAPI: EditorScriptFlowAPI,
  { firstInstall, withEcom }: SplitPagesOptions,
) {
  const installedPages = await editorSDK.pages.getApplicationPages(TOKEN);
  const expectedPages = withEcom ? MPA_ON_ECOM_PAGES : MPA_PAGES;

  if (firstInstall) {
    const missingPages = expectedPages.filter((page) => page.tpaPageId !== TpaPageId.PackagePicker);
    await splitPagesMigration(flowAPI, editorSDK, missingPages);
    await installRouter(flowAPI, editorSDK, expectedPages);
  } else {
    const routers = await editorSDK.routers.getAll(TOKEN);
    const missingPages: PageInstallationInfo[] = [];
    for (const expectedPage of expectedPages) {
      if (!installedPages.find((installedPage) => installedPage.tpaPageId === expectedPage.tpaPageId)) {
        missingPages.push(expectedPage);
      }
    }
    if (routers.length > 0 && missingPages.length > 0) {
      await splitPagesMigration(flowAPI, editorSDK, missingPages);
      await installRouter(flowAPI, editorSDK, expectedPages);
    }
  }

  await setPageStates(editorSDK);

  editorSDK.addEventListener(EventType.appVisitedInDashboard, async () => {
    await editorSDK.editor.routers.refresh(TOKEN);
    await editorSDK.tpa.app.refreshApp(TOKEN);
  });
}

function toRef(page: PageData): PageRef {
  return { id: page.id!, type: 'DESKTOP' };
}

export async function setPageStates(sdk: EditorSDK) {
  const pages = await sdk.pages.getApplicationPages(TOKEN);
  const state: Record<string, PageRef[]> = {
    checkout: pages.filter(({ tpaPageId }) => tpaPageId === TpaPageId.Checkout).map(toRef),
    customization: pages.filter(({ tpaPageId }) => tpaPageId === TpaPageId.PlanCustomization).map(toRef),
    paywall: pages.filter(({ tpaPageId }) => tpaPageId === TpaPageId.Paywall).map(toRef),
    thank_you: pages.filter(({ tpaPageId }) => tpaPageId === TpaPageId.ThankYou).map(toRef),
    ecom_thank_you: pages.filter(({ tpaPageId }) => tpaPageId === 'thank_you_page').map(toRef),
  };
  if (Object.values(state).some((statePages) => statePages.length)) {
    await sdk.pages.setState(TOKEN, { state });
  }
}

export async function splitPagesMigration(
  flowAPI: EditorScriptFlowAPI,
  sdk: EditorSDK,
  pagesToInstall: PageInstallationInfo[],
) {
  try {
    flowAPI.fedops.interactionStarted('router_split_pages');
    await markPackagePickerManaged(sdk);
    await changePackagePickerSlug(sdk, 'list');
    for (const { tpaPageId, pageData } of pagesToInstall) {
      const pageRef = await retry({ times: 1, delay: 500 }, () => installPage(sdk, tpaPageId));
      if (pageData && pageRef) {
        await sdk.document.pages.data.update('', { pageRef, data: pageData });
      }
    }
    flowAPI.fedops.interactionEnded('router_split_pages');
  } catch (e) {
    captureEditorException(flowAPI, e, {
      interactionTag: 'router_split_pages',
      print: true,
    });
  }
}

async function installPage(sdk: EditorSDK, tpaPageId: TpaPageId): Promise<PageRef> {
  let pageRef = await sdk.document.tpa.getPageRefByTPAPageId(TOKEN, { tpaPageId });
  if (!pageRef) {
    const component = await sdk.document.tpa.add.component(TOKEN, {
      appDefinitionId,
      managingAppDefId: appDefinitionId,
      componentType: 'PAGE',
      page: {
        pageId: tpaPageId,
        isHidden: true,
        shouldNavigate: false,
      },
    });
    if ('pageRef' in component && component.pageRef) {
      pageRef = component.pageRef;
    } else {
      throw new Error(`Adding component ${tpaPageId} failed - pageRef unavailable`);
    }
  }
  return pageRef;
}

async function markPackagePickerManaged(sdk: EditorSDK) {
  const pages = await sdk.pages.data.getAll(TOKEN);
  for (const page of pages) {
    if (page.appDefinitionId === appDefinitionId && !page.managingAppDefId && page.id) {
      await sdk.pages.data.update(TOKEN, {
        pageRef: { id: page.id, type: 'DESKTOP' },
        data: { managingAppDefId: appDefinitionId, indexable: true },
      });
    }
  }
}

async function changePackagePickerSlug(sdk: EditorSDK, slug: string) {
  const pages = await sdk.pages.data.getAll(TOKEN);
  for (const page of pages) {
    if (page.tpaPageId === 'membership_plan_picker_tpa' && page.id) {
      await sdk.pages.data.update(TOKEN, {
        pageRef: { id: page.id, type: 'DESKTOP' },
        data: { pageUriSEO: slug },
      });
    }
  }
}
